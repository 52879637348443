import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

type LoadingPropsTypes = {
  loading: any;
  children: JSX.Element;
  notWidht?: boolean;
  textColor?: string;
  img?: boolean;
};

const Loading = React.memo<LoadingPropsTypes>(
  ({ loading, children, notWidht, textColor, img }) => {
    return (
      <div
        className={
          notWidht
            ? 'app-loading-wrapper-not-width'
            : img
            ? 'app-loading-wrapper-img'
            : 'app-loading-wrapper'
        }
      >
        {loading ? (
          <div className="app-loading-overlay">
            <span style={{ color: `${textColor}` }}>Loading...</span>
            <CircularProgress
              style={{ width: '20px', height: '20px', color: 'grey' }}
            />
          </div>
        ) : null}
        {children}
      </div>
    );
  }
);

export default Loading;
